import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import Burger from "./Burger";
import DarkBurger from "./DarkBurger";
import "./DarkNav.css";


const Nav = styled.nav`
  font-family: "Inter";
  width: 100%;
  z-index: 10;
`;

function Navbar2() {
//     const [show, setShow] = useState(true);

//     useEffect(() => {
//         let LastScrollY = window.scrollY;

//     window.addEventListener("scroll", ()=>{
//         if(LastScrollY < window.scrollY){
//             setShow(false)
//         } else {
//             setShow(true)
//         }
//          LastScrollY = window.scrollY;
//     })
// }, [])

// ${show && "myNav"}




    return (
        // <Nav className={`nav `}>
        <div className="navbard">
            <Link to="/" className="navbard_logo">
                <Link to="/"> <p>TUSHAR SHARMA</p> </Link>
            </Link>
            <DarkBurger />
        </div>

        //  </Nav>
    )
}

export default Navbar2