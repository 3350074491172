import { motion } from "framer-motion";


const transition = (OgComponent) => {
return () => (
    <>
        <OgComponent /> 
        <motion.div
            className="slide-in"
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 0 }}
            exit={{ scaleY: 1 }}
            transition={{ duration: 1.5, ease:[0.22, 1, 0.36, 1] }}
        />
        <motion.div
            className="slide-out"
            initial={{ scaleY: 1 }}
            animate={{ scaleY: 0 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 1.5, ease:[0.22, 1, 0.36, 1] }}
        />
    </>
);
};

export default transition;


// import { motion } from "framer-motion";
// const animationConfiguration = {
//     initial: { opacity: 0 },
//     animate: { opacity: 1 },
//     exit: { opacity: 0 },
//     // initial: { scaleY: 0 },
//     // animate: { scaleY: 1 },
//     // exit: { scaleY: 0 },
// };
// const Transitions = ({ children }) => {
//     return (
//         <motion.div
//             variants={animationConfiguration}
//             initial="initial"
//             animate="animate"
//             exit="exit"
//             transition={{ duration: 1.5 }}
//             // transition={{ duration: 2, ease:[0.22, 1, 0.36, 11] }}

//         >
//             {children}
//         </motion.div>
//     );
// };
// export default Transitions;