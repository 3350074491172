import React from 'react';
import "./Testimonials.css";

function Testimonials() {
  return (
    <div className='testimonials'>
        <div className='testimonials_title'>
        <div className='testimonials_Category'>
          <p>Heartfelt</p>
          <p style={{marginRight:"-220px"}}><span>Recommendations </span></p>
        </div>
        <img src="/images/Line.svg" alt="line" />
      </div>

      <div className='testimonials_Recommendation'>
        <div className='testimonials_Recommendation_Left'>
            <div className='testimonials_Recommendation_Left_Text'>
                <p>Working with Tushar Sharma has been a fantastic experience. He has consistently proven himself as an exceptional design partner. Tushar's responsiveness to feedback and projects is unmatched, always approaching them with empathy for the customer and a sense of urgency for the business. His understanding of our business goals and customer needs has been impressive, leading to fresh and creative solutions for each project. I thoroughly enjoyed collaborating with him, his talent and dedication would undoubtedly be a valuable asset to any organization.</p>
            </div>
            <div className='testimonials_Recommendation_Left_Designation'>
                <p>Karran Gupta</p>
                <p><em>Digital Product Manager | <span>Rocket Central</span></em></p>
            </div>
        </div>
        <div className='testimonials_Recommendation_Right'>
            <img src='./images/temp.jpg' alt="image" />
        </div>
      </div>

<div className='testimonials_Recommendation_Duo_duo'>
      <div className='testimonials_Recommendation_Duo'>
        <div className='testimonials_Recommendation_Left'>
            <div className='testimonials_Recommendation_Top'>
                <img src="./images/temp.jpg" alt="image" />
                <p>Tushar brings fresh ideas and innovation to the team. In his time here, he has challenged our own ideas of what works, pushing forward a design. Tushar is extremely adaptable and responds well to feedback. He is eager to learn new things and step outside of his comfort zone, exhibiting a sense of curiosity that exceeds from of most team members. Lastly, he proactively looks for and shares interesting design patterns he finds in the wild, demonstrating that he's not here just to take orders and do a job, but rather can think strategically about external design elements that may be applicable to our business.</p>
            </div>
            <div className='testimonials_Recommendation_Left_Designation'>
                <p>Amanda Peters</p>
                <p><em>Digital Product Manager | <span>Rocket Central</span></em></p>
            </div>
        </div>
        <div className='testimonials_Recommendation_Right_right'>
        <div className='testimonials_Recommendation_Left'>
            <div className='testimonials_Recommendation_Top'>
                <img src="./images/Allison.png" alt="image" />
                <p>Coming from a technology background, he has quickly proven himself as an exceptional experience designer, showcasing remarkable skills and dedication to his craft. Throughout his internship, Tushar has been a driving force behind several successful design initiatives. His ability to combine technical knowledge, design expertise, and fresh, innovative ideas has resulted in solutions that have positively impacted our projects. Collaboration is one of Tushar's strong qualities as he seamlessly works with designers, product managers, and engineers. With Tushar's passion for design and his remarkable work ethic, I have no doubt that he will continue to achieve great success. His potential is limitless, and I am excited to see what the future has in store for him.</p>
            </div>
            <div className='testimonials_Recommendation_Left_Designation'>
                <p>Allison O'Keefe</p>
                <p><em>Senior Experience Designer | <span>Rocket Central</span></em></p>
            </div>
        </div>
        </div>
      </div>
      </div>

      <div className='testimonials_Recommendation'>
        <div className='testimonials_Recommendation_Left'>
            <div className='testimonials_Recommendation_Left_Text'>
                <p>Working with Tushar Sharma has been a fantastic experience. He has consistently proven himself as an exceptional design partner. Tushar's responsiveness to feedback and projects is unmatched, always approaching them with empathy for the customer and a sense of urgency for the business. His understanding of our business goals and customer needs has been impressive, leading to fresh and creative solutions for each project. I thoroughly enjoyed collaborating with him, his talent and dedication would undoubtedly be a valuable asset to any organization.</p>
            </div>
            <div className='testimonials_Recommendation_Left_Designation'>
                <p>Karran Gupta</p>
                <p><em>Digital Product Manager | <span>Rocket Central</span></em></p>
            </div>
        </div>
        <div className='testimonials_Recommendation_Right'>
            <img src='./images/temp.jpg' alt="image" />
        </div>
      </div>



    </div>
  )
}

export default Testimonials;