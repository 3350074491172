import React, { useEffect, useRef } from 'react';
import "./Rocket.css";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import Navbar2 from './Navbar2';
import DarkNav  from './DarkNav';
import Footer from './Footer';
import SmoothScroll from "smooth-scroll";
import ScrollToTop from "react-scroll-to-top";
import transition from '../transition';





function Rocket() {

  useEffect(() => {
    window.scrollTo(0,0);
},[])


// const { scrollYProgress } = useScroll();
// const scaleX = useSpring(scrollYProgress, {
//   stiffness: 100,
//   damping: 30,
//   restDelta: 0.001
// });


// useEffect(() => {
//     window.scrollTo(0,0);
// },[])

// var scroll = new SmoothScroll('a[href*="#"]', {
//     speed: 300,
// });


const { scrollYProgress } = useScroll();
const { scrollY } = useScroll();
const x = useTransform(scrollY, [3000,5000], [0, -700])


const scaleX = useSpring(scrollYProgress, {
  stiffness: 100,
  damping: 30,
  restDelta: 0.001
});

const ref = useRef(null);
const { scrollXProgress } = useScroll({ container: ref });




  return (
    // <>
    //         <Transitions>
    <div className='Rocket'>
      <DarkNav  />
      <motion.div className="progress-bar-Rocket" style={{ scaleX }} />

       <div className='rocket_Header'>
                <div className='homeLeft_rocket'>
                    <div className='homeLeft_Top_rocket'>
                        <p>Designing</p>
                    </div>
                    <div className='homeLeft_Bottom_rocket'>
                        <p>State of the Art</p>
                        <p>Lead Gen form for</p>
                        <img src="/images/RM.svg" alt="logo" />
                    </div>
                </div>
                <div className='project_Info project_Info_Rocket'>
                    <div className='project_Info_Timeline project_Info_Timeline2'>
                        <h5>TIMELINE</h5>
                        <p>May 2023 - August 2023</p>
                    </div>
                    <div className='project_Info_Role'>
                        <h5>ROLE</h5>
                        <p>UX Designer & Stratergy</p>
                    </div>
                    <div className='project_Info_Role'>
                        <h5>TEAM</h5>
                        <p>2 UX Designer <br />
                           2 Content Writers<br />
                           3 PMs</p>
                    </div>
                    <div className='project_Info_Role'>
                        <h5>WORK</h5>
                        <p>Revamped the top of the funnel form and making it<br /> personalized for users with different needs  </p>
                    </div>
                </div>
            </div>

            {/* <div style={{margin:"25px 0px"}}>
                <p>PROJECT SHOWREEL</p>
            </div>

            <div className='project_Video'>
                <h1>VIDEO</h1>
            </div> */}

            <div className='project_Overview'>
                <div className='project_Overview_Left'>
                    <p>OVERVIEW</p>
                    <div className='project_Overview_Left_Link'>
                        <p><a data-scroll style={{textDecoration:"none", color:"#000000"}} href='#solution'>SKIP TO THE JUICY PART</a></p>
                        <img src='/images/RocketLinkArrow.svg' alt="link arrow" />
                    </div>
                </div>
                <div className='project_Overview_Right'>
                  <h2>ABOUT ROCKET MORTGAGE</h2>
                    <p>Rocket Mortgage is America’s largest mortgage lender, helping millions achieve the dream of home ownership. Their mission is to  make the complicated home financing process simpler</p>
                    <br />
                    <h2>WHAT DOES THE LEAD GENERATION TEAM DO?</h2>
                    <p>The lead generation team operates at the top of the mortgage platform funnel. We provide users with a form to fill out, and then we connect them with a banker, aiming to successfully convert them into customers.</p>
                </div>
            </div>

            <div className='border2 border3'></div>


            <div className='business_request'>
              <p>BUSINESS REQUEST:</p>
              <p> &nbsp;  &nbsp;  &nbsp;  &nbsp;The business required us to create a smart form that can replace the current form. We were asked to create a new, smart, lead generation form for Rocket Mortgage which would allow the users to navigate according to their needs and lastly to see their personalized mortgage rates.</p>
            </div>

            <div className='why_tackle_problem'>
                <h1>But why do we need to tackle this problem?</h1>
                <div className='why_tackle_problem_content'>
                    <p>After a lot fo research done by UX Researchers and data marketing analysts, we found that there was a HUGE drop-off rate by the time users reached the end of the form.</p>
                    <img src="/images/DropOff_Number.svg" alt="drop-off rate number" />
                </div>
            </div>



            {/* <div className='project_Video'>
                <h1>VIDEO</h1>
            </div> */}



            <div className='border2 border3'></div>


            <div className='project_Research_Header project_Research_Rocket '>
                <div className='project_Research_Header_Left project_Research_Header_Left_Rocket'>
                <div className='project_Research_Header_Left_Top'>

                </div>
                <div className='project_Research_Header_Left_Bottom'>
                    <p>Problems:</p>
                </div>

                </div>
                <div className='project_Research_Header_Right project_Research_Header_Right_Rocket'>
                    <div className='project_Research_Header_Right_Top'>
                        <h1>Current</h1>
                        <h1>Problems</h1>
                        <p>The research was done by researchers and analysts by analyzing comments from Google reviews, feedback from users through user testing.com, looking at analytics of the form.</p>

                    </div>
                    <div className='project_Research_Header_Right_Bottom'>
                        <p>1. Bankers don’t get all the information</p>
                        <p>2. Users have to fill out the same form irrespective of their need</p>
                        <p>3. Users gets spam calls from the banker after filling Personal Information </p>
                    </div>
                </div>
            </div>
            <img className='userresearch' src="/images/UserReviewsRocket.svg" alt="User Reviews" />

            <div className='project_Vague_ProblemStatement project_Vague_ProblemStatement_Rocket'>
                <h2>THE PROBLEM STATEMENT</h2>
                <p>Before beginning the design journey we went on to define the problem statement that helped us design and stay on track in the later stages</p>
                <p>How might we design a user-centric smart lead generation form that empowers users to intuitively navigate through the process, personalize their experience, and effortlessly view their personalized mortgage rates?</p>
            </div>



            <div className='project_Research_Header old_version'>
                <div className='project_Research_Header_Left old_version_left'>
                <div className='project_Research_Header_Left_Top'>

                </div>
                <div className='project_Research_Header_Left_Bottom'>
                    <p>Revamp the experience:</p>
                </div>

                </div>
                <div className='project_Research_Header_Right old_version_right'>
                    <div className='project_Research_Header_Right_Top'>
                        <h1>Ideation</h1>
                        <h1>Process</h1>
                    </div>
                    <div className='project_Research_Header_Right_Bottom project_Research_Header_Right_Bottom_Rocket'>
                        <h1> &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp;                     We completely redesigned the form and divided it into 3 categories, by doing 15 split testing making it easier for the user to see the rates and get personalized experience.</h1>
                        <p>We began by coming up with ideas on how to make the user experience unique based on their choices. <b>After brainstorming a lot, we conducted split tests on the progress bar, buttons, changing content etc.</b> </p>
                    </div>
                </div>
            </div>

            <div className='numbers_rocket'>
              <p>Results</p>
              <img src="/images/ResultsRocket.svg" alt="results" />
            </div>

            <div className='roekctvideo'>
              <video autoPlay="true" loop="true" src='/images/rocketv.mov' />
            </div>

            <p className='results'>Success was that the new tests we performed were doing better than the current ones. <br />
On average, the success rate of the spit tests in comparison with the control versions was <span>16%</span></p>
            <div className='border2 border3'></div>

            <div className='project_Secondary_Research project_IA IA_Rocket'>
                <div className='project_Secondary_Research_Wrapper project_IA_Wrapper'>
                    <div className='project_Secondary_Research_Wrapper_Left'>
                        <p>RECREATING THE FLOW</p>
                    </div>
                    <div className='project_Secondary_Research_Wrapper_Right'>
                        <p>We engaged in strategic planning and restructured the questionnaire. We revised the wording of the questions to make them friendlier, eliminated unnecessary ones, and introduced new questions. Following these revisions, we categorized the questions into three distinct groups.</p>
                    </div>
                </div>
                <div className='RIA'>
                <img src="/images/IARocket.svg" alt='Information Architecture Rocket' />
                </div>
            </div>




            <div className='project_Research_Header old_version RDS'>
                <div className='project_Research_Header_Left old_version_left'>
                <div className='project_Research_Header_Left_Top'>

                </div>
                <div className='project_Research_Header_Left_Bottom'>
                    <p>New look:</p>
                </div>

                </div>
                <div className='project_Research_Header_Right old_version_right'>
                    <div className='project_Research_Header_Right_Top'>
                        <h1>Design</h1>
                        <h1>System</h1>
                    </div>
                    <div className='project_Research_Header_Right_Bottom project_Research_Header_Right_Bottom_Rocket'>
                        <p> &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp;                      Next, we adapted to Rocket's new design system, crafting additional components and refining existing ones for a seamless transition. <br /> <b>Some of the components we designed were the TY page widgets, countdown timer, drop-down etc.</b></p>
                    </div>
                </div>
            </div>

            <img className='RDSImage' src="/images/RDS.svg" alt="RDS image" />

            <div className='project_Final_Solution_Bottom_Bottom'>
                            <img src="/images/ColorRocket.svg" alt="colour palette" />
                        <div className='project_Final_Solution_Tank_Right project_Final_Solution_Tank_RightRocket'>
                            <div className='project_Final_Solution_Tank_Right_left'>
                                <h2>Colors</h2>
                                {/* <img src="/images/Arrow 5.svg" alt="arrow" /> */}
                            </div>
                            <div className='project_Final_Solution_Tank_Right_Right'>
                                <p>We adapted to the new colors in the Rocket Design System with the primary color being Red. This color scheme completely changed the visual design of the flow.</p>
                            </div>
                        </div>
                    </div>



                    <div className='project_Research_Header old_version RDS' id='solution'>
                <div className='project_Research_Header_Left old_version_left project_Research_Header_Left_Rocket'>
                <div className='project_Research_Header_Left_Top'>

                </div>
                <div className='project_Research_Header_Left_Bottom'>
                    <p>Final Solution V1:</p>
                </div>

                </div>
                <div className='project_Research_Header_Right old_version_right project_Research_Header_Left_Rocket'>
                    <div className='project_Research_Header_Right_Top'>
                        <h1>Experience</h1>
                        <h1>Design</h1>
                    </div>
                    <div className='project_Research_Header_Right_Bottom project_Research_Header_Right_Bottom_Rocket'>
                        <p> &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; Stitching all the pieces together, we created a personalized experience for the users. We redesigned the entire flow, with new animations, micro interactions and styling. We also created new different Thank You pages that shows users their rates.</p>
                    </div>
                </div>
            </div>


            <div className='project_Final_Solution_Bottom_Top_Left RocketSolOne'>
                            <div className='project_Final_Solution_Bottom_Top_Left_Left'>
                                <div className='project_Final_Solution_Bottom_Top_Left_Left_Flex'>
                                    <h2>Showing Users Rates</h2>
                                    <p>We've customized the Thank You pages to display general rates for users and included a timer indicating when a banker will call to provide them with their specialized rates.</p>
                                </div>
                                {/* <img src="/images/Arrow1.svg" alt="arrow" /> */}
                            </div>
                            <div className='project_Final_Solution_Bottom_Top_Left_Right project_Final_Solution_Bottom_Top_Left_RightRocket '>
                                <video autoPlay="true" loop="true" src='/images/RocketRates.mov'/>
                            </div>
                        </div>


            <div className='project_Final_Solution_Bottom_Bottom'>
                        <div className='project_Final_Solution_Tank_left project_Final_Solution_Tank_leftRocket'>
                            <video autoPlay="true" loop="true" src='/images/RocketGuide.mov'/>
                        </div>
                        <div className='project_Final_Solution_Tank_Right ShowingUserRates'>
                            <div className='project_Final_Solution_Tank_Right_left'>
                                <h2>Guiding Users</h2>
                                {/* <img src="/images/Arrow 5.svg" alt="arrow" /> */}
                            </div>
                            <div className='project_Final_Solution_Tank_Right_Right'>
                                <p>Providing some additional insights to the users based upon the option they select, guiding them towards their goal.</p>
                            </div>
                        </div>
                    </div>


                    <div className='project_Secondary_Research project_IA IA_Rocket'>
                <div style={{marginTop: "-40px"}} className='project_Secondary_Research_Wrapper project_IA_Wrapper'>
                    <div className='project_Secondary_Research_Wrapper_Left'>
                        <p style={{letterSpacing: "0px"}}>Improving Visual Feedback</p>
                    </div>
                    <div className='project_Secondary_Research_Wrapper_Right'>
                        <p>We added the loading animation to provide visual feedback to the users that this is not just a normal form but we are actually working on their inputs on the back-end.</p>
                    </div>
                </div>
                  <video style={{marginTop: "50px"}} autoPlay="true" loop="true" src='/images/Animation.mov'/>
            </div>



            <div className='project_Final_Solution_Bottom_Top_Left RocketSolOne'>
                            <div className='project_Final_Solution_Bottom_Top_Left_Left'>
                                <div className='project_Final_Solution_Bottom_Top_Left_Left_Flex'>
                                    <h2>Assisting Users with Unfamiliar Questions</h2>
                                    <p>We uderstand that user's might not understand/know answers to some question like purchase price of a home in a locality, so we will be providing them an average estimate based upon the loaction they choose.</p>
                                </div>
                                {/* <img src="/images/Arrow1.svg" alt="arrow" /> */}
                            </div>
                            <div className='project_Final_Solution_Bottom_Top_Left_Right project_Final_Solution_Bottom_Top_Left_RightRocket2 '>
                                <video autoPlay="true" loop="true" src='/images/Assisting.mov'/>
                            </div>
                        </div>


            <div className='project_Final_Solution_Bottom_Bottom'>
                        <div className='project_Final_Solution_Tank_left project_Final_Solution_Tank_leftRocket2'>
                            <img src="/images/PTYPage.svg" alt="arrow" />
                        </div>
                        <div className='project_Final_Solution_Tank_Right ShowingUserRates'>
                            <div className='project_Final_Solution_Tank_Right_left'>
                                <h2>Personalized Thank You Pages</h2>
                                {/* <img src="/images/Arrow 5.svg" alt="arrow" /> */}
                            </div>
                            <div className='project_Final_Solution_Tank_Right_Right'>
                                <p>Each user is different and have different needs, so we personalized the TY pages based upon the answers that the users select in the questionnaire.</p>
                            </div>
                        </div>
                    </div>


                    {/* <p className='results'>Version 1 of launchpad is currently under testing, stay tuned to see the results in a few months!</p>

                    <div className='border2 border3'></div> */}

                    <div className='numbers_rocket'>
              <p>Impactful Numbers</p>
              <img src="/images/NumbersRocket.svg" alt="numbers" />
            </div>

            <div className='border2 border3'></div>


                    <div className='project_Reflection'>
                <h2>REFLECTION</h2>
                <div className='project_Reflection_Text'>
                    <div className='project_Reflection_Text_Left'>
                        <img src="/images/Key.svg" alt="reflection image" />
                        <h2>Collaboration is the key</h2>
                        <p>Projects will be successful only if there is constant collaboration  between the PMs, designers, dev and the marketing team.</p>
                    </div>
                    <div className='project_Reflection_Text_Middle'>
                        <img src="/images/Bulb.svg" alt="reflection image" />
                        <h2>Communicate complex/unique ideas</h2>
                        <p>Never be afraid to communicate complex/unique ideas, you might never know if the thing will work or not unless you build and test it.</p>
                    </div>
                    <div className='project_Reflection_Text_Right'>
                        <img src="/images/Empathy.svg" alt="reflection image" />
                        <h2>Empathize with everyone and embrace feedback</h2>
                        <p>Show compassion and understanding towards all individuals, fostering a culture of inclusivity. Welcome feedback as a valuable tool for personal growth and continuous improvement.</p>
                    </div>
                </div>
                <ScrollToTop 
                color='#FB8842'
                top={500} smooth />

            </div>

            <Footer />





    </div>
    // </Transitions>
    // </>
  )
}

export default transition(Rocket);
