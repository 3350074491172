import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Navbar from "./Components/Navbar"
import Home from './Components/Home';
import About from "./Components/About";
import Resume from "./Components/Resume";
import Fitbit from './Components/Fitbit';
import Privacy from './Components/Privacy';
import Finergic from './Components/Finergic';
// import IUJobs from ' ./Components/IUJobs';
import {CSSTransition, TransitionGroup} from "react-transition-group"
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { useEffect, useState } from 'react';
import Update from './Components/Update';





function App() {
  const location = useLocation();
  
  return (
    <div className="App">
      {/* <Router> */}
 
        {/* <Navbar />  */}
        {/* <Router> */}
        <AnimatePresence mode='wait'>
          <Routes location={location} key={location.pathname} >
            <Route index path="/" element={<Update /> } />  
            {/* <Route index path="/home" element={<Home /> } />              
            <Route exact path="/about" element={<About />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/case-studies/rocket-mortgage" element={<Privacy />} />
            <Route path="/case-studies/finergic" element={<Finergic />} />
            <Route path="/case-studies/fitbit" element={<Fitbit />} /> */}
          </Routes>
          </AnimatePresence>
          {/* </Router> */}
          {/* <motion.div
          className='cursor'
          variants={variants}
          animate="default"
          /> */}

       </div>
  );
}

export default App;
